import { faPause, faPlay, faStop } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { HorizontalLayout } from '@nic/ui-comps'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from '@nic/commons'

/** Visualizza lo stato del conteggio, Attivo o Pausa **/
export interface RequestCountdownStatusProps {
  status: 'stop' | 'play' | 'pause'
}

export function CountdownStatus(props: RequestCountdownStatusProps) {

  const t = useTranslation()

  const icon = () => {
    switch (props.status) {
      case 'play':
        return (faPlay) as IconProp
      case 'stop':
        return (faStop) as IconProp
      case 'pause':
        return (faPause) as IconProp
    }
  }

  const text = () => {
    switch (props.status) {
      case 'play':
        return `${t('reqCountdownStatus.active')}`
      case 'stop':
        return  `${t('reqCountdownStatus.close')}`
      case 'pause':
        return  `${t('reqCountdownStatus.pause')}`
    }
  }

  const iconColor = () => {
    switch (props.status) {
      case 'play':
        return '#41A9FF'
      case 'stop':
        return '#FF603C'
      case 'pause':
        return '#c6c6c6'
    }
  }


  return <div style={{
    // border: '1px solid #F5F5F5',
    // borderRadius: 5,
  }}>
    <div style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.45)' }}>
      <b>{ `${t('reqCountdownStatus.counting')}`}</b>
    </div>
    <HorizontalLayout style={{
      justifyContent: 'space-around',
      // border: '1px solid #F5F5F5',
      // borderRadius: 5,
      padding: '5px 30px',
      gap: 10,
    }}>
      <div style={{
        border: '1px solid #F5F5F5',
        borderRadius: 5,
        padding: '2px 7px 0px 7px',
      }}>
        <FontAwesomeIcon
          icon={icon()}
          style={{
            color: iconColor(),
            fontSize: 30,
          }}
        /></div>
      <div>
        <span
          style={{
            color: iconColor(),
            fontWeight: '700',
          }}>{text()}</span>
      </div>
    </HorizontalLayout></div>
}