import { Flex } from '@nic/ui-comps'
import {
  ApiTwoTone,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  InfoCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { IRequestProgress, MainRequestProgressView, pippoColor as stageColor } from '../index'
import React from 'react'
import { useTranslation } from '@nic/commons'
import { RequestTimeline } from '../useRequestTimeline'
import Tag from 'antd/lib/tag'
import { ExpirationCountdown } from './ExpirationCountdown'
import { MetaDeadline } from './MetaDeadline'
import { CountdownStatus } from './CountdownStatus'
import { Tooltip } from 'antd'

export interface RequestTimelineProgressProps extends IRequestProgress {
  hasDeactivatedDomains: boolean
  requestTimeline: RequestTimeline
}


export function RequestTimelineProgress(props: RequestTimelineProgressProps) {
  const t = useTranslation()

  const iconStyle = (_fontSize = '1.5em') => {
    return { margin: '15px 5px', fontSize: _fontSize }
  }
  const flexStyle = {
    justifyContent: 'stretch',
    width: '100%',
  }
  // const isFirstDeadlineDayZero = props.firstDeadline.remainingDays <= 1 && props.secondDeadline.remainingDays >= props.secondDeadline.duration
  // const isDeactivationDomainsProgress = props.firstDeadline.remainingDays === 0 && props.secondDeadline.remainingDays === props.secondDeadline.duration
  // const isSecondDeadline = props.secondDeadline.remainingDays <= props.secondDeadline.duration && props.firstDeadline.remainingDays <= 1
  // const isRequestOver = Date.parse(props.secondDeadline.endDate as string) < Date.now()

  console.log('RequestTimelineProgress', props.requestTimeline)

  const request_first_deadline_title = (
    <div>
      <WarningOutlined style={{
        color: props.hasDeactivatedDomains ? '#c6c6c6' : 'orange',
        fontSize: '1.3em',
      }} />{`${t('requestProgress.firstDeadlineDuration')}`} <ApiTwoTone
      style={{ fontSize: '1.3em' }}
      twoToneColor={props.hasDeactivatedDomains ? '#c6c6c6' : stageColor(props.paused, props.requestTimeline.firstDeadline.remainingDays)}
      spin={!props.hasDeactivatedDomains && props.requestTimeline.isFirstDeadlineClosing}
    />
    </div>
  )

  const first_deadline_description = props.hasDeactivatedDomains ? (
    <Tag color="error" icon={<ApiTwoTone />}>{`${t('requestProgress.deactivatedDomains')}`}</Tag>
  ) : (
    <>{`${t('requestProgress.requestFirstDeadlineDescription')}`}</>
  )

  const extra_deadline_description = !props.hasDeactivatedDomains && props.requestTimeline.isFirstDeadlineClosing ?
    <div style={{ fontSize: 14, color: '#c6c6c6' }}>
      {`${t('requestProgress.requestFirstDeadlineInProgress')}`}
      <Tooltip
        title={t('requestProgress.requestFirstDeadlineInProgressTooltip')}>
        <InfoCircleOutlined
          style={{ fontSize: 14, color: '#c6c6c6', marginLeft: 10 }} />
      </Tooltip>
    </div>
    : <></>

  const countingStatus = (): 'stop' | 'play' | 'pause' => {
    if (props.paused) {
      return 'pause'
    } else if (props.requestTimeline.isRequestOver) {
      return 'stop'
    } else {
      return 'play'
    }
  }

  return (
    <Flex direction={'row'}>
      <div style={{ width: '90%', textAlign: 'center' }}>
        {/* PRIMA LINEA (TOP LINE) */}
        <Flex direction={'row'} className={'request-timeline-first-line'}>
          {/* Giorni rimasti PRIMA DEADLINE*/}
          <ExpirationCountdown
            style={{ width: '37%' }}
            lastDay={props.requestTimeline.isFirstDeadlineDayZero}
            value={props.hasDeactivatedDomains ? 0 : new Date(props.requestTimeline.firstDeadline.endDate).getTime()}
            pause={props.paused}
            remainingDays={props.requestTimeline.firstDeadline.remainingDays}
          />
          {/* Giorni rimasti SECONDA DEADLINE*/}
          <ExpirationCountdown
            style={{ width: '63%' }}
            lastDay={props.expirationDays === 1}
            value={props.requestTimeline.isSecondDeadlineActive ? new Date(props.requestTimeline.secondDeadline.endDate).getTime() : undefined}
            pause={props.paused}
            remainingDays={props.requestTimeline.secondDeadline.remainingDays}
          />
        </Flex>

        {/* SECONDA LINEA (MIDDLE LINE) */}
        <Flex className={'request-timeline-center-line'} direction={'row'} style={flexStyle}>
          <CheckCircleTwoTone style={iconStyle()} twoToneColor="#52c41a" />
          <div style={{ width: '36%' }} className={'first-deadline-progress-bar'}>
            <MainRequestProgressView
              remainingDays={props.requestTimeline.firstDeadline.remainingDays}
              isPaused={props.paused || false}
              percentage={props.requestTimeline.firstDeadline.percentage}
              isOverLabel={`${t('requestProgress.over')}`}
              // isOverLabel={'Domains Inactive'}
              type={'line'}
            />
          </div>
          <span style={{ fontSize: '1.5em', fontWeight: '700' }}>|</span>
          <div style={{ width: '60%' }} className={'first-deadline-progress-bar'}>
            <MainRequestProgressView
              remainingDays={props.requestTimeline.secondDeadline.remainingDays}
              isPaused={props.paused || false}
              percentage={props.requestTimeline.secondDeadline.percentage}
              isOverLabel={`${t('requestProgress.over')}`}
              // isOverLabel={'Domains Inactive'}
              type={'line'}
            />
          </div>
          <CloseCircleTwoTone
            style={iconStyle()}
            twoToneColor={stageColor(props.paused, props.requestTimeline.secondDeadline.remainingDays)}
          />
        </Flex>

        {/* TERZA LINEA (BOTTOM LINE)*/}
        <Flex className={'request-timeline-footer-line'} direction={'row'} style={flexStyle}>
          {/* Data di apertura */}
          <MetaDeadline
            align={'left'}
            style={{ width: '20%' }}
            title={`${t('requestProgress.open')}`}
            date={props.creationDate.toString()} />
          {/* Data prima deadline */}
          <MetaDeadline
            align={'center'}
            style={{
              width: '35%',
              color: props.hasDeactivatedDomains ? '#c6c6c6' : stageColor(props.paused, props.requestTimeline.firstDeadline.remainingDays),
            }}
            iconColor={props.hasDeactivatedDomains ? '#c6c6c6' : stageColor(props.paused, props.requestTimeline.firstDeadline.remainingDays)}
            title={request_first_deadline_title}
            description={first_deadline_description}
            extra={extra_deadline_description}
            date={props.requestTimeline.firstDeadline.endDate.toString()}
            paused={props.paused}
            pausedDescription={`${t('requestProgress.reloading')}`}
          />

          {/* Data seconda deadline, chiusura  */}
          <MetaDeadline
            align={'right'}
            style={{
              width: '45%', color: stageColor(props.paused, props.requestTimeline.secondDeadline.remainingDays),
            }}
            iconColor={stageColor(props.paused, props.requestTimeline.secondDeadline.remainingDays)}
            title={`${t('requestProgress.requestIsOverTitle')}`}
            description={`${props.requestTimeline.secondDeadline.remainingDays > 0 ? t('requestProgress.requestIsExpiringDescription') : t('requestProgress.requestIsOverDescription')}`}
            paused={props.paused}
            pausedDescription={`${t('requestProgress.reloading')}`}
            date={props.requestTimeline.secondDeadline.endDate.toString()}
          />
        </Flex></div>
      <div style={{ padding: '10px 0px', border: '1px solid #E7E7E7', marginLeft: 10, borderRadius: 10 }}>
        <CountdownStatus status={countingStatus()} />
      </div>
    </Flex>
  )
}


