import { AssignmentRequestActionsProps } from '../ButtonsAssignmentRequestActions'
import { useTranslation } from '@nic/commons'
import { useScopeInfo } from '../../Hooks/useScopeInfo'
import { Button, notification, Tag, Tooltip } from 'antd'
import React from 'react'
import { useAssignRequestToUser } from './useAssignRequestToUser'
import { printAssignmentRequestActionError } from './printAssignmentRequestActionError'
import { UserSwitchOutlined } from '@ant-design/icons'
import { stringify } from 'qs'
import { ModalRegistrarUserList } from './ModalRegistrarUserList'
import { useAssignmentRequest } from '../useAssignmentRequest'
import { printAxiosError } from '../../../Utils/common'
import { useRouter } from '../../Hooks/useRouter'

export function ButtonAssignToUser(props: AssignmentRequestActionsProps) {
  const t = useTranslation()
  const { scope } = useScopeInfo()
  const { loading, error, handleAssignRequestToUser } = useAssignRequestToUser()
  const { isRegistrarAssignedUser, isUserDarwinAdmin } = useAssignmentRequest()
  const hasNoPermission = scope === 'registrar' ? isRegistrarAssignedUser : !isUserDarwinAdmin
  const router = useRouter()
  const [showModal, setShowModal] = React.useState<boolean>(false)

  const handleOnClick = (user: any) => {
    try {
      handleAssignRequestToUser(props.requestId, user.id, scope).then((r) => {
        if (props.onSuccess) {
          props.onSuccess && props.onSuccess()
        } else {
          router.history.push(
            router.pathname + '?' + stringify({ ...router.query, reload: new Date() })
          )
        }
      })
    } catch (e) {
      notification.error({ message: printAxiosError(e), placement: 'top' })
    }
  }

  if (error) {
    return <Tag color={'red'}>{printAssignmentRequestActionError({ error })}</Tag>
  }

  return (
    <>
      <ModalRegistrarUserList
        showModalAccept={showModal}
        setShowModalAccept={setShowModal}
        onOk={handleOnClick}
      />

      <Tooltip title={t('assignmentRequests.action.assignToUser')}>
        <Button
          icon={<UserSwitchOutlined />}
          type={'link'}
          disabled={hasNoPermission}
          loading={loading}
          onClick={() => setShowModal(true)}
        />
      </Tooltip>
    </>
  )
}
