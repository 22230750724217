import React, { useEffect } from 'react'
import { Footer } from './Footer'
import { Header } from './Components/Layout/Header'
import { LayoutManager } from './Context/LayoutContext'
import { usersAbilities } from './setupAbilites'
import { StateOrProvinceProvider } from '@nic/ui-antd'
import { NicScope } from '@nic/nic-api/types'
import { SelectUserProvider } from './Components/Providers/SelectedUserContext'
import { AuthProvider, useAuth } from '@nic/kc-auth'
import { AbilityProvider } from '@nic/commons'
import { Routes } from './Routes'
import Keycloak from 'keycloak-js'
import { api } from '@nic/nic-api'

interface AppI {
  conf: string
  locale?: string
}

const App: React.FC<AppI> = (props) => {
  const [keycloakJson, setKeycloakJson] = React.useState<Keycloak.KeycloakConfig>(
    {} as Keycloak.KeycloakConfig
  )
  useEffect(() => {
    fetch(props.conf as string)
      .then((response) => response.json())
      .then((data) => {
        const conf = { realm: data.realm, url: data['auth-server-url'], clientId: data.resource }
        setKeycloakJson(conf)
      })
      .catch((error) => console.error('Error fetching data:', error))
  }, [props.conf])

  // console.log('INSPECTION ->', App.name)
  //evita la doppia chiamata delle componenti sotto
  if (keycloakJson.clientId && keycloakJson.realm && keycloakJson.url) {
    return (
      <AuthProvider keycloakConfig={keycloakJson}>
        <InnerApp />
      </AuthProvider>
    )
  } else {
    return <>Waiting conf</>
  }
}

export default App

/**
 * Inserito questo componente perchè c'è da controllare che il token di KC
 * sia arrivato altrimenti StateOrProvince va in errore 401 perchè troppo veloce
 * e ancora senza token ...anche se in verità dovrebbe già averlo a bordo (da capire perchè
 * viene eseguito prima
 * TODO: C'è da capire come migliorare
 * */
function Body() {
  const { keycloak } = useAuth()

  return keycloak?.authenticated ? (
    <>
      <StateOrProvinceProvider scope={keycloak.realm as NicScope}>
        <SelectUserProvider>
          <Routes />
        </SelectUserProvider>
      </StateOrProvinceProvider>
    </>
  ) : (
    <>There is some problem with the authorization token</>
  )
}

const InnerApp: React.FC = () => {
  const { keycloak } = useAuth() // Recupera il client dal contest
  const HeaderMemo = React.memo(Header)

  if (keycloak) {
    api(keycloak)
    return (
      <AbilityProvider restrictions={usersAbilities} keycloak={keycloak}>
        <LayoutManager
          header={<HeaderMemo title={<span style={{ fontSize: '2.0rem' }}>darwin</span>} />}
          body={<Body />}
          footer={<Footer />}
        />
      </AbilityProvider>
    )
  } else {
    return <div>Authenticating...</div>
  }
}
