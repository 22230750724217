import { TabPaneProps, Tabs } from 'antd'
import React, { useContext } from 'react'
import { useTranslation } from '@nic/commons'
import { RequestContext } from '../../../../../../Contexts/RequestContext'
import { ActionButtonDelete, ActionButtonHold } from '../ActionButtons/ActionButtons'
import { ContactsContext } from '../../../../../../Providers/ContactsProvider'
import { CandidateRegistrantContext } from '../../../../../../Providers/CandidateRegistrant'
import { CandidateOrReferenceDetailCard } from './common'
import { NicScope } from '@nic/nic-api/types'
import {
  ContactListFullDetailed,
  IDarwinContactExtended,
} from '../../../../../../ContactListFullDetailed/ContactListFullDetailed'
import { useRegistrarPermissions } from '../../../../../../Hooks/useRegistrarPermissions'
import { useRouter } from '../../../../../../Hooks/useRouter'

const { TabPane } = Tabs

export function ContactsToUpdateTab(props: TabPaneProps) {
  const t = useTranslation()
  const {
    state: { toDo, loading },
    actions,
    dispatch,
  } = useContext(ContactsContext)
  const { stateReqAnomalies, scope, id, stateReqById } = useContext(RequestContext)
  const { hasRegistrarNotProcessRequestPermission } = useRegistrarPermissions()

  const { state: stateCandidate } = useContext(CandidateRegistrantContext)
  const router = useRouter()
  const isRegistrar = scope === 'registrar'
  const isPending = stateReqById.data?.status === 'pending'

  //console.log('Contacts toUpdate', toDo)

  const actionColumns = (record: IDarwinContactExtended) => {
    return [
      <ActionButtonHold
        key={'b_hold'}
        onClick={() => {
          id && actions.changeContactAction(id, record.darwinId, 'hold', scope)(dispatch)
        }}
        disabled={!isPending || !isRegistrar || hasRegistrarNotProcessRequestPermission}
      />,
      <ActionButtonDelete
        key={'b_delete'}
        onClick={() => {
          id && actions.changeContactAction(id, record.darwinId, 'delete', scope)(dispatch)
        }}
        disabled={!isPending || !isRegistrar || hasRegistrarNotProcessRequestPermission}
      />,
    ]
  }
  const MemoizedContactListFullDetailed = React.memo(ContactListFullDetailed)

  const onChangePageHandler = (page?: number, pageSize?: number) => {
    router.pushHelper({ page: page, size: pageSize })
    actions.getContacts(id, 'todo', page, pageSize, scope)(dispatch)
  }

  return (
    <TabPane
      tab={t('contactsToUpdateTab.tabTitle', { count: toDo.page.totalElements })}
      key={props.tabKey}
    >
      <MemoizedContactListFullDetailed
        readonly={stateReqById.data?.status === 'completed'}
        contacts={toDo}
        contactDetailTitle={t('contactsToUpdateTab.contactDetailTitle')}
        anomalies={stateReqAnomalies.data}
        scope={scope}
        domainContactDetailTitle={t('contactsToUpdateTab.domainContactDetailTitle')}
        actionColumn={actionColumns}
        loading={loading}
        onChangePagination={onChangePageHandler}
        showAdditionalContactDetailCard
        additionalContactDetailCard={
          <CandidateOrReferenceDetailCard
            candidateContact={stateCandidate.data}
            scope={scope as NicScope}
          />
        }
      />
    </TabPane>
  )
}
