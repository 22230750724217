import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { LocaleStore } from '@nic/commons'
import '@fontsource/titillium-web'
import { Main } from './Main'
import { BrowserRouter, Switch } from 'react-router-dom'
// replace console.* for disable log on production

// permette di non avere i messaggi di log negli ambienti diversi da sviluppo
if (process.env.NODE_ENV !== 'development') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <LocaleStore locale={'it'}>
        <React.Suspense fallback={<span>Loading...</span>}>
          <Main />
        </React.Suspense>
      </LocaleStore>
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
export { getWindowRunConfig } from './darwinConfig'
