export interface RequestTimeline {
  endDate: string,
  remainingDays: number,
  percentage: number,
  firstDeadline: IRequestTimelineDeadline,
  secondDeadline: IRequestTimelineDeadline
  isFirstDeadlineDayZero: boolean,
  isFirstDeadlineClosing: boolean,
  isSecondDeadlineActive: boolean,
  isRequestOver: boolean
}


export function useRequestTimeline(
  creationDate: string,
  entireDuration: number,
  firstDeadlineDuration: number,
  remainingDays: number,
  currentTime: string,
): RequestTimeline {

  // Durata in giorni tra la prima e seconda scadenza
  const secondDeadlineWindow = entireDuration - firstDeadlineDuration
  // Giorni alla fine totale

  const firstDeadlineRemainingDays =
    remainingDays - secondDeadlineWindow > 0 ? remainingDays - secondDeadlineWindow : 0

  const secondDeadlineRemainingDays =
    entireDuration - remainingDays > firstDeadlineDuration ? remainingDays : secondDeadlineWindow

  // const aab = {
  //   creationDate,
  //   entireDuration,
  //   firstDeadlineDuration,
  //   remainingDays,
  //   secondDeadlineWindow,
  // }
  // console.log('AAB', aab)

  const percentage =
    remainingDays > 0 ? ((entireDuration - remainingDays) / entireDuration) * 100 : 100

  const secondDeadlineEndDate = calcEndDate(creationDate, remainingDays, currentTime).toString()
  const firstDeadlineEndDate = calcEndDate(creationDate, firstDeadlineRemainingDays, currentTime).toString()

  // console.log("firstDeadlineEndDate",creationDate.toString(),firstDeadlineRemainingDays,calcEndDate(creationDate, firstDeadlineRemainingDays, currentTime).toString())

  return {
    endDate: calcEndDate(creationDate, remainingDays, currentTime).toString(),
    remainingDays,
    percentage,
    firstDeadline: {
      duration: firstDeadlineDuration,
      endDate: firstDeadlineEndDate,
      remainingDays: firstDeadlineRemainingDays,
      percentage:
        firstDeadlineRemainingDays > 0
          ? ((firstDeadlineDuration - firstDeadlineRemainingDays) / firstDeadlineDuration) * 100
          : 100,
    } as IRequestTimelineDeadline,
    secondDeadline: {
      duration: entireDuration - firstDeadlineDuration,
      endDate: secondDeadlineEndDate,
      remainingDays: secondDeadlineRemainingDays,
      percentage:
        secondDeadlineRemainingDays > 0
          ? ((secondDeadlineWindow - secondDeadlineRemainingDays) / secondDeadlineWindow) * 100
          : 100,
    } as IRequestTimelineDeadline,
    isFirstDeadlineDayZero: firstDeadlineRemainingDays < 1 && secondDeadlineRemainingDays >= secondDeadlineWindow,
    // TODO: da verificare se è corretto aver tolto il controllo sul secondDeadlineRemainingDays === entireDuration
    // isFirstDeadlineClosing: firstDeadlineRemainingDays === 0 && secondDeadlineRemainingDays === entireDuration,
    isFirstDeadlineClosing: firstDeadlineRemainingDays === 0 ,
    isSecondDeadlineActive: secondDeadlineRemainingDays <= entireDuration && firstDeadlineRemainingDays <= 1,
    isRequestOver: Date.parse(secondDeadlineEndDate) <= Date.parse(currentTime),
  }
}

export function calcEndDate(_startDate: string, _days: number, _refDate: string) {
  const startDate = new Date(_startDate)
  let refDate = new Date(_refDate)
  refDate.setHours(startDate.getHours(), startDate.getMinutes(), startDate.getSeconds(), startDate.getMilliseconds())
  if (_days > 0) {
    // return addDays(new Date(_startDate), _days)

    return new Date(refDate.getTime() + _days * 24 * 60 * 60 * 1000)
    // return new Date(new Date(_startDate).getTime() + 1000 * 60 * 60 * 24 * _days + 1000 * 30)
  } else {
    return refDate
  }
}

export interface IRequestTimelineDeadline {
  duration: number
  remainingDays: number
  percentage: number
  endDate: number | string
}
