import React from 'react'
import { formatDate } from '../../../Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Flex } from '@nic/ui-comps'
import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons'
import './TimeTag.css'

const TimeTag = (props: {
  created: string
  color: string
  fontWeight?: number
  fontSize?: string
  bordered?: boolean
  hideTime?: boolean
}) => {
  const fontWeight = props.fontWeight ?? 400
  const bordered = props.bordered ?? false
  const className = bordered ? `timeTag timeTag-${props.color ?? 'gray'}` : ''
  const fontSize = props.fontSize ?? '0.8rem'
  return (
    <Flex className={'time_tag'} direction={'row'} justify={'start'} style={{ gap: '5px' }}>
      <Flex
        direction={'row'}
        align={'center'}
        style={{ gap: '5px', fontSize: fontSize, minWidth: 60 }}
        className={`${className}`}
      >
        <FontAwesomeIcon color={props.color} icon={faCalendar as IconProp} />
        <div className="time_tag_value" style={{ fontWeight: fontWeight }}>
          {formatDate(props.created, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })}
        </div>
      </Flex>
      {!props.hideTime && (
        <Flex
          direction={'row'}
          style={{ gap: '5px', fontSize: fontSize }}
          className={`${className}`}
        >
          <FontAwesomeIcon color={props.color} icon={faClock as IconProp} />
          <span style={{ fontWeight: fontWeight }}>
            {formatDate(props.created, { hour: 'numeric', minute: 'numeric' })}
          </span>
        </Flex>
      )}
    </Flex>
  )
}
export default TimeTag
