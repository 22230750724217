import { AbilityContext, useTranslation } from '@nic/commons'
import { Darwin, IPage } from '@nic/nic-api/types'
import { ColumnBuilder } from '@nic/ui-antd'
import React from 'react'
import { stringify } from 'qs'
import { Divider } from 'antd'
import { IRequest } from '@nic/nic-api/types/Darwin/types'
import { isIRequestExpiring } from '../../Utils'
import { Datatable } from '../Datatable'
import { requestsTableRegistrar } from './requestsTableRegistrar'
import { requestsTableRegistry } from './requestsTableRegistry'
import { useAuth } from '@nic/kc-auth'
import { useRouter } from '../Hooks/useRouter'

export interface IRequestTableData extends Darwin.Request {
  domains: number
  contacts: number
  events: number
}

interface IRequestsTableProps {
  /** tipo di vista che si vuole selezionare **/
  userView?: 'registry' | 'registrar'
  data?: IRequestTableData[]
  page?: IPage
  loading?: boolean
}

/**
 * View che mostra una Tabella di Requests.˙
 * Tramite il parametro view si può decidere se visualizzare per 'registry' o per 'registrar'
 *
 * @param userView
 * @param stateRequests
 * @constructor
 */

export const RequestsTable = ({
  userView = 'registrar',
  data,
  loading,
  page,
}: IRequestsTableProps) => {
  const { keycloak } = useAuth()
  const ability: any = React.useContext(AbilityContext)
  const t = useTranslation()
  const realm = keycloak?.realm
  const router = useRouter()
  /* esiste l'oggetto referenceContact? */
  const hasReferenceContact = data?.some((value) => {
    return typeof (value as IRequest).referenceContact == 'object'
  })
  const dtView =
    userView === 'registrar'
      ? new ColumnBuilder()
          .setColumn('id')
          .setColumn('status')
          .setColumn('created')
          .setColumn('extra', ['pending', undefined].includes(router.query.status as any))
          .setColumn('referenceContact', hasReferenceContact)
          .setColumn('contacts')
          .setColumn('domains')
          // .setColumn("events")
          .setColumn('expirationDays', data && isIRequestExpiring(data[0]))
          .setColumn(
            'registrarUser',
            ['all', 'assigned'].includes(router.query.registrarAssignation as string)
          )
          .setColumn('action', ability.can('assignedToMe', 'ViewAssignationRequests'))
          .build()
      : new ColumnBuilder()
          .setColumn('id')
          .setColumn('registrarId', ability.can('View', 'Registrar'))
          .setColumn('userId', ability.can('View', 'User'))
          .setColumn('status')
          .setColumn(
            'extra',
            ['pending', undefined, 'registrarUpdated'].includes(router.query.status as any)
          )
          .setColumn('referenceContact', hasReferenceContact)
          .setColumn('created')
          .setColumn('registrarUser')
          .setColumn(
            'registryUser',
            ['all', 'assigned'].includes(router.query.registryAssignation as string)
          )
          .setColumn('contacts')
          .setColumn('domains')
          .setColumn('events', false)
          .setColumn('expirationDays', data && isIRequestExpiring(data[0]))
          .setColumn('action')
          .build()

  const dtRender: Record<any, any> =
    userView === 'registrar'
      ? requestsTableRegistrar(router, data, keycloak)
      : requestsTableRegistry(realm, data, keycloak)

  const current = page?.number ?? 0

  return (
    <Datatable
      loading={loading}
      view={dtView}
      data={data || []}
      page={{
        showTotal: (tot) => (
          <span>
            <span style={{ margin: 10 }}>
              {`${t('Founds')}: `}
              <b>{tot}</b>
            </span>
            <Divider type={'vertical'} />
          </span>
        ),
        total: page?.totalElements ?? 0,
        pageSize: page?.size ?? 0,
        current: current + 1,
      }}
      render={dtRender}
      changePageHandler={(pageSize: number, nextPage: number) => {
        const qp = router.query
        const url =
          router.location.pathname +
          stringify({ ...qp, page: nextPage - 1, size: pageSize }, { addQueryPrefix: true })
        router.push(url)
      }}
    />
  )
}
