import { Div, PaperLayout } from '@nic/ui-comps'
import React, { useContext } from 'react'
import { useTranslation } from '@nic/commons'
import { CircleRequestProgress } from '../Feedback/RequestProgress/CircleRequestProgress/CircleRequestProgress'
import { useRequestInfo } from '../Hooks/useRequestInfo'
import { RequestExpirationDaysContext } from '../Providers/RequestExpirationDaysProvider'
import { PaperTitle } from '../Layout/PaperTitle'
import { Spin } from 'antd'
import { AxiosError } from 'axios'
import { isEmpty } from 'lodash'
import { ErrorAlert } from '../ErrorAlert/ErrorAlert'
import { getWindowRunConfig } from '../../darwinConfig'
import {
  RequestTimelineProgressContainer,
} from '../Feedback/RequestProgress/RequestTimelineProgress/RequestTimelineProgressContainer'

/**
 * Card che presenta un Progress circolare che indica la scadenza della Richiesta
 * @constructor
 */
export function CircolarValidityRequestCard(props: { paused: boolean }) {
  return (
    <ValidityRequestCard>
      {(created, expirationDays) => (
        <Div textAlign={'center'}>
          <CircleRequestProgress
            creationDate={created}
            expirationDays={expirationDays}
            duration={getWindowRunConfig().DARWIN_REQUEST_DURATION}
            paused={props.paused}
          />
        </Div>
      )}
    </ValidityRequestCard>
  )
}

/**
 * Card che presenta un Progress lineare che indica la scadenza della Richiesta
 * @constructor
 */
export function LinearValidityRequestCard(props: { paused: boolean }) {
  console.log('DATEs:', props)
  return (
    <ValidityRequestCard>
      {(created, expirationDays) => (
        <Div textAlign={'center'}>
          <RequestTimelineProgressContainer
            creationDate={created}
            expirationDays={expirationDays}
            duration={getWindowRunConfig().DARWIN_REQUEST_DURATION_CANCELLATION}
            paused={props.paused}
          />
          {/*<LinearRequestProgress*/}
          {/*  creationDate={created}*/}
          {/*  expirationDays={expirationDays}*/}
          {/*  duration={getWindowRunConfig().DARWIN_REQUEST_DURATION_CANCELLATION}*/}
          {/*  paused={props.paused}*/}
          {/*/>*/}
        </Div>
      )}
    </ValidityRequestCard>
  )
}

/**
 * Card base che offre le info per mostrare la scadenza della Richiesta
 * @constructor
 */
export const ValidityRequestCard: React.FC<{
  children: (created: string, expirationDays: number) => void
}> = (props) => {
  const t = useTranslation()
  const { hasExpirationDays, request } = useRequestInfo()
  const {
    state: { loading, data, error },
  } = useContext(RequestExpirationDaysContext)
  return (
    <PaperLayout asCard={false}>
      <PaperTitle showIcon title={t('cardRequestValidity.title')} />
      {loading && <Spin />}
      {(!hasExpirationDays || error) &&
        (!hasExpirationDays || (error && error.response?.data.code === 1003) ? (
          <div>{t('requestProgress.notCompatibleStatus')}</div>
        ) : (
          <ErrorAlert error={error as AxiosError} />
        ))}
      {!loading &&
        isEmpty(error) &&
        request?.created &&
        data?.count !== undefined &&
        props.children(request.created, data?.count)}
    </PaperLayout>
  )
}
