import { useTranslation } from '@nic/commons'
import React from 'react'
import { Div, PaperLayout } from '@nic/ui-comps'
import { Registrar } from '@nic/ui-antd'
import { Darwin, NicScope } from '@nic/nic-api/types'
import { RequestsTable } from '../../Components/RequestsTable/RequestsTable'
import { PaperTitle } from '../../Components/Layout/PaperTitle'
import { Breadcrumb } from '../../Components/Breadcrumb/Breadcrumb'
import { LayoutContext } from '../../Context/LayoutContext'
import { useRequestTable } from '../../Components/RequestsTable/useRequestTable'
import { useAuth } from '@nic/kc-auth'
import { useRouter } from '../../Components/Hooks/useRouter'

/**
 * Pagine che visualizza
 * @constructor
 */
export function Registrars() {
  const router = useRouter()
  const queryParams = router.params as Darwin.GetRequestParams
  const t = useTranslation()
  const layout = React.useContext(LayoutContext)
  const { keycloak } = useAuth()
  const realm = keycloak?.realm
  React.useEffect(() => {
    layout.setLayoutElement(
      'BREADCRUMB',
      <Breadcrumb
        elements={[
          { label: t('breadcrumb.dashboard'), path: `/${realm}/dashboard` },
          { label: t('breadcrumb.registrars'), path: `/${realm}/registrars` },
          {
            label: `${t('breadcrumb.registrar')}: ${queryParams.registrarId}`,
            path: `/${realm}/registrars/${queryParams.registrarId}`,
          },
        ]}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.registrarId])
  const { loading, requests } = useRequestTable(queryParams, realm as NicScope)

  return (
    <Div textAlign={'center'} width={'100%'}>
      <PaperLayout asCard={true}>
        <PaperTitle
          title={
            queryParams.registrarId && (
              <Registrar
                id={queryParams.registrarId}
                render={(r) => <>{t('requestPerRegistrars', { name: r.name })}</>}
              />
            )
          }
        />
        <RequestsTable
          userView={realm as NicScope}
          data={requests?.elements}
          loading={loading}
          page={requests?.page}
        />
      </PaperLayout>
    </Div>
  )
}
