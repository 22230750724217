import React from 'react'
import { RegistryRegistrantCards } from '../../../../../Cards/RegistryRegistrantCards/RegistryRegistrantCards'

export type RegistryProfileProps = {
  isRegistrarUpdatedStatus: boolean
  hasExpirationDays?: boolean
  loading?: boolean
}

/***
 * Visualizzazione nel caso di profilo Registry
 * @constructor
 */
export const RegistryProfile: React.FC<RegistryProfileProps> = () => (
  <RegistryRegistrantCards />
)
