import React from 'react'
import { Flex, PaperLayout } from '@nic/ui-comps'
import { IRequestTableData, RequestsTable } from './RequestsTable'
import { NicScope } from '@nic/nic-api/types'
import { Can, useTranslation } from '@nic/commons'
import { useRequestTable } from './useRequestTable'
import { parse } from 'qs'
import { ButtonsAssignationRequestsView } from '../AssignmentRequests/ButtonsAssignationRequestsView'
import axios from 'axios'
import { ButtonsRequestStatusView } from '../Status/ButtonsRequestStatusView'
import { IRequestStatus } from '../../../../nic-react-monorepo/packages/nic-api/types/Darwin/types'
import { Space } from 'antd'
import { useHistory } from 'react-router'
import { useRouter } from '../Hooks/useRouter'

/**
 * Controller per visualizzare la tabella.
 * Esegue la fetch dei dat
 * @param props
 * @constructor
 */
export function RequestsTableController(props: {
  /** tipo di vista che si vuole selezionare **/
  userView?: 'registry' | 'registrar'
  /** parametri di ricerca da passare  **/
  registrarId?: number
  title: string
}) {
  const { query } = useRouter()
  const t = useTranslation()
  let history = useHistory()
  let arraysOfViews = query.view ? parse('view=' + query.view, { comma: true }) : undefined
  // se Registry omette il parametro registrarId
  const qp =
    props.userView === 'registry'
      ? {
          ...query,
          ...arraysOfViews,
          // @ts-ignore
          registrarId: query?.registrarId?.value,
        }
      : {
          ...query,
          ...arraysOfViews,
          registrarId: props.registrarId,
        }

  const { loading, requests, error } = useRequestTable(qp as any, props.userView as NicScope)

  return (
    <PaperLayout asCard={true}>
      {/*<PaperTitle*/}
      {/*  title={*/}
      {/*    <Space>*/}
      {/*      {props.title}*/}
      {/*      <StatusView plural status={(query.status as IRequestStatus) ?? 'all'} />*/}
      {/*    </Space>*/}
      {/*  }*/}
      {/*/>*/}

      <Flex direction={'row'} justify={'space-between'} style={{ marginBottom: 15 }}>
        <Space>
          {!history.location.pathname.includes('requests_expiring') && (
            <>
              <b>{t('Status')}</b>
              <ButtonsRequestStatusView />
            </>
          )}
        </Space>
        <Can a={'ViewAssignationRequests'} do={'assignedToMe'}>
          {['pending', 'registrarUpdated', undefined].includes(query.status as IRequestStatus) && (
            <Space>
              <b>{t('Assignments')}</b>
              <ButtonsAssignationRequestsView />
            </Space>
          )}
        </Can>
      </Flex>
      {axios.isAxiosError(error) ? (
        <div style={{ color: 'red' }}>{error.message}</div>
      ) : (
        <div style={{ color: 'red' }}>{JSON.stringify(error)}</div>
      )}
      <RequestsTable
        userView={props.userView}
        data={requests?.elements as IRequestTableData[]}
        page={requests?.page}
        loading={loading}
      />
    </PaperLayout>
  )
}
