import { useRequestTimeline } from '../useRequestTimeline'
import { getWindowRunConfig } from '../../../../darwinConfig'
import { IRequestProgress } from '../index'
import { RequestTimelineProgress } from './RequestTimelineProgress'
import React, { useContext } from 'react'
import { RequestContext } from '../../../Contexts/RequestContext'

export function RequestTimelineProgressContainer(props: IRequestProgress) {
  const timeElapsed = Date.now()
  const now = new Date(timeElapsed)

  const rt = useRequestTimeline(
    props.creationDate,
    getWindowRunConfig().DARWIN_REQUEST_DURATION_CANCELLATION,
    getWindowRunConfig().DARWIN_REQUEST_DURATION_DEACTIVATION,
    props.expirationDays,
    now.toString()
  )
  const { stateReqEvents } = useContext(RequestContext)

  console.log('RequestTimelineProgressContainer props:', now.toString(),props)
  // console.log('RequestTimelineProgressContainer firstDeadline:', firstDeadline)
  // console.log('RequestTimelineProgressContainer secondDeadline:', secondDeadline)
  console.log('RequestTimelineProgressContainer rt:', rt)
  const hasDeactivatedDomains = !!stateReqEvents.data?.elements.find((event) => event.type === 'domainsDeactivated')

  return (
    <RequestTimelineProgress
      hasDeactivatedDomains={hasDeactivatedDomains}
      creationDate={props.creationDate}
      expirationDays={props.expirationDays}
      duration={props.duration}
      paused={props.paused}
      requestTimeline={rt}
    />
  )
}
