import { Darwin } from '../../../../nic-react-monorepo/packages/nic-api/types'

type eventExtended =
  | 'registrarUserAssignation'
  | 'registrarUserRelease'
  | 'registryUserAssignation'
  | 'registryUserRelease'
export const EventTypeColor: Record<Darwin.EventType & eventExtended, string> = {
  expiredCompleted: '',
  // magenta
  // red
  // volcano
  // orange
  // gold
  // lime
  // green
  // cyan
  // blue
  // geekblue
  // purple
  submitted: 'green', // registro
  toPending: 'blue', // registro
  rejected: 'red', // registro
  cancelled: 'red', // registro
  registrarUpdated: 'orange', // registrar
  registryUpdated: 'purple', // registrar
  registrarUpdateRejected: 'red', // registro
  updatedRequiredDocuments: 'orange', //registro
  registrarUpdateApproved: 'green', // registro
  toProcessing: 'orange', // registrar
  expired: 'red', // registro
  cancelledCompleted: 'red', // registro
  completed: 'green', // registro
  registrarUserAssignation: 'blue',
  registrarUserRelease: 'orange',
  registryUserRelease: 'orange',
  registryUserAssignation: 'blue',
  domainsDeactivated: 'red',
}

export const EventTypeAlertType: Record<
  Darwin.EventType & eventExtended,
  'info' | 'warning' | 'error' | 'success' | undefined
> = {
  expiredCompleted: 'success',
  cancelledCompleted: 'warning', // registro
  registryUpdated: 'warning', // registrar
  cancelled: 'info', // registro
  submitted: 'success', // registro
  updatedRequiredDocuments: 'warning', //registro
  toPending: 'info', // registro
  rejected: 'error', // registro
  registrarUpdated: 'info', // registrar
  registrarUpdateRejected: 'error', // registro
  registrarUpdateApproved: 'success', // registro
  toProcessing: 'warning', // registrar
  expired: 'error', // registro
  completed: 'success', // registro
  registrarUserAssignation: 'warning',
  registrarUserRelease: 'error',
  registryUserAssignation: 'warning',
  registryUserRelease: 'error',
  domainsDeactivated: 'warning',

}
