import React from 'react'
import { HorizontalLayout, VerticalLayout } from '@nic/ui-comps'
import { RegistryProfileProps } from '../RegistryProfile/RegistryProfile'
import { RequestTabType } from '../../../../index'
import { RegistrarRegistrantCard } from '../../../../../Cards/RegistrarRegistrantCard/RegistrarRegistrantCard'
import { DocumentsCard } from '../../../../../Cards/DocumentsCard'

export type RegistrarProfileProps = RegistryProfileProps & {
  onChangeTab?: (index: RequestTabType) => void
}
/***
 *  * Visualizzazione nel caso di profilo Registrar
 * @param isRegistrarUpdatedStatus
 * @param onChangeTab
 * @constructor
 */

export const RegistrarProfile: React.FC<RegistrarProfileProps> = ({
  onChangeTab,
}) => (
  <HorizontalLayout
    style={{
      width: '100%',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      // padding: '0 10px 0 10px',
      gap: 10,
    }}
  >
    <RegistrarRegistrantCard />
    <VerticalLayout
      style={{
        alignItems: 'stretch',
        width: '550px',
      }}
    >
      <DocumentsCard />
    </VerticalLayout>

  </HorizontalLayout>
)
