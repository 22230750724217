import { NicScope } from '@nic/nic-api/types'
import { useAuth } from '@nic/kc-auth'
import { useRouter } from './useRouter'

/***
 * Ritorna le informazioni delle richiesta se già caricata
 */
export function useScopeInfo() {
  const { keycloak } = useAuth()
  const router = useRouter()
  const { id: requestId } = router.match.params as { id: number }
  const scope = keycloak?.realm as NicScope
  return { scope, requestId }
}
