import React from 'react'
import { DarwinHelpers, fetchRequestsInitialState, fetchRequestsReducer } from '@nic/nic-api'
import * as _ from 'lodash'
import { NicScope } from '@nic/nic-api/types'
import { useAuth } from '@nic/kc-auth'

export interface IRegistrarsListRow {
  registrarId: string
  count: number
}

/**
 * Ritorna la groupBy e count dei Registrars trovati in tutte le richieste
 */
export function useRequestsRegistrars() {
  const [stateReqs, dispatchReqs] = React.useReducer(
    fetchRequestsReducer,
    fetchRequestsInitialState
  )

  const { keycloak } = useAuth()

  const scope: NicScope = keycloak?.realm as NicScope
  React.useEffect(() => {
    DarwinHelpers.requests(dispatchReqs, { size: 10000 }, scope)
  }, [scope])

  function registrarsList(): IRegistrarsListRow[] {
    return _.default(stateReqs.data?.elements)
      .groupBy('registrarId')
      .map(function (items, registrarId) {
        return {
          registrarId: registrarId,
          count: items.length,
        } as IRegistrarsListRow
      })
      .value()
  }

  return registrarsList()
}
