import React from 'react'
import { Flex } from '@nic/ui-comps'
import TimeTag from '../../../Various/TimeTag/TimeTag'
import { PauseCircleTwoTone } from '@ant-design/icons'

export interface MetaDeadlineProps {
  align: 'left' | 'right' | 'center'
  style?: React.CSSProperties,
  iconColor?: string,
  date: string
  paused?: boolean
  title: React.ReactElement | string,
  description?: React.ReactElement | string,
  extra?: React.ReactElement | string,
  pausedDescription?: React.ReactElement | string,
}

/** Visualizza le date di scadenza **/
export function MetaDeadline(
  {
    iconColor = 'lightBlue',
    style,
    description,
    date,
    title,
    extra,
    align,
    paused,
    pausedDescription,
  }: MetaDeadlineProps) {
  return <div
    style={{
      ...style,
      textAlign: align,
    }}
  >
    <div style={{ fontSize: 16 }}>{title}</div>
    <div style={{ fontSize: 14 }}>{description}</div>
    <div style={{ fontSize: 14 }}>{extra}</div>
    <Flex direction={'row'} style={{ fontSize: 14 }} justify={align}>
      {paused ? <><PauseCircleTwoTone twoToneColor={'#c6c6c6'} style={{ marginRight: 10 }} />
          <div style={{ fontStyle: 'oblique' }}>{pausedDescription}</div>
        </> :
        <TimeTag
          created={date}
          color={iconColor}
          fontWeight={700}
          bordered={false}
        />}
    </Flex>
  </div>
}