import { PaperTitle } from '../../Layout/PaperTitle'
import React, { useContext } from 'react'
import { PaperLayout } from '@nic/ui-comps'
import { useTranslation } from '@nic/commons'
import { Card, message, Space } from 'antd'
import { Darwin, NicScope } from '@nic/nic-api/types'
import { DarwinHelpers, RequestDocumentDeleteService } from '@nic/nic-api'
import { RequestContext } from '../../Contexts/RequestContext'
import { useRequestInfo } from '../../Hooks/useRequestInfo'
import { RequiredDocuments } from '../../Documents/RequiredDocuments/RequiredDocuments'
import { ReferenceContext } from '../../Providers/ReferenceProvider'
import { RenderDocumentItem } from '../../Documents/RenderDocumentItem/RenderDocumentItem'
import { RequiredDocumentsWarning } from '../../Documents/RequiredDocumentsWarning/RequiredDocumentsWarning'
import { DocumentList } from '../../Documents/DocumentList/DocumentList'

export interface DocumentsCardProps {
  /** lista documenti Darwin con la rispettiva risposta del server **/
  uploadedDocumentList: Darwin.DarwinUploadedFileResponse[]
  /** metodo per visualizzare la riga di presentazione del Documento **/
  renderDocumentItem?: (selected: Darwin.DarwinUploadedFileResponse) => React.ReactNode
  error?: any
  loading?: boolean
  scope: NicScope
}

/**
 * Componente Base per la visualizzazione di una Card che presenta:
 * - una lista di documenti richiesti diversificata per registry, registra
 * - una albero di documenti uplodati nel sistema Darwin e aggregati per tipologia (DocType)
 * @constructor
 */
export const DocumentsCard: React.FC = () => {
  const t = useTranslation()
  const {
    scope,
    stateReqDocuments,
    dispatchReqDocuments,
    id,
    stateRequiredDocuments: { loading, error, data: reqDocsData },
  } = useContext(RequestContext)
  const { request } = useRequestInfo()
  const { stateReferenceDarwin } = useContext(ReferenceContext)
  const entityType = stateReferenceDarwin.data?.registrant?.entityType
  const { stateReqById } = useContext(RequestContext)
  const hasManagementStatus = request
    ? request.status === 'registrarUpdated' || request.status === 'pending'
    : false

  const handleOnDelete = (_docId: string, _scope: NicScope) => {
    RequestDocumentDeleteService(_docId, _scope)
      .then(() => {
        message.success(t('docSuccessfullyRemoved', { docId: _docId }))
        DarwinHelpers.documents(dispatchReqDocuments, { requestId: id }, scope as NicScope)
      })
      .catch((e) => {
        message.error(e.response.data.message)
        console.error(JSON.stringify(e))
      })
  }
  return (
    <PaperLayout asCard={false} style={{ height: '100%' }}>
      <PaperTitle showIcon title={t('cardDocuments.title')} />
      <Space direction="vertical" style={{ width: '100%' }}>
        {entityType && (
          <Card title={<b>{t('cardDocuments.cardRequiredDocuments')}</b>}>
            <RequiredDocuments
              entityType={entityType}
              loading={loading}
              enableRequiredDocsModal={hasManagementStatus}
              initialRequiredDocumentList={reqDocsData || stateReqById.data}
            />
          </Card>
        )}
        <RequiredDocumentsWarning />
        <DocumentList
          error={error}
          loading={loading}
          scope={scope as NicScope}
          uploadedDocumentList={stateReqDocuments.data?.elements || []}
          renderDocumentItem={(selected) =>
            RenderDocumentItem(selected, scope as NicScope, (docId) =>
              handleOnDelete(docId, scope as NicScope)
            )
          }
        />
      </Space>
    </PaperLayout>
  )
}
