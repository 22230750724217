import React from 'react'
import { Drawer } from 'antd'
import { CloseCircleOutlined, MenuOutlined } from '@ant-design/icons'
import { Div, Flex, MediaQuery, VerticalLayout } from '@nic/ui-comps'
import { LanguageSwitcher } from './LanguageSwitcher'
import { UserInfo } from './UserInfo'
import { useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { Can, useTranslation } from '@nic/commons'
import { NicScope } from '../../../../nic-react-monorepo/packages/nic-api/types'
import { useAuth } from '@nic/kc-auth'

interface HeaderI {
  height?: string
  fontSize?: string
  backgroundColor?: string
  title: string | React.ReactNode
}

export const Header: React.FC<HeaderI> = (props) => {
  const { title } = props
  const fontSize = props.fontSize ?? '1.1rem'
  const history = useHistory()
  const { keycloak } = useAuth()
  const realm = keycloak?.realm as NicScope
  const location = useLocation()
  const isRegistrar = realm === 'registrar'

  const renderMenuItem = (
    path: string,
    label: string,
    match: string,
    colorMenu: string,
    fSize?: string,
    rendered?: boolean
  ) => {
    const { pathname, search } = location

    const selected = pathname + search === match

    const render = rendered ?? true

    const item = render ? (
      <Link
        to={path}
        style={{
          color: colorMenu,
        }}
      >
        <span>{label}</span>
      </Link>
    ) : (
      <span>{label}</span>
    )

    return (
      <Div
        padding={'10px'}
        color={render ? colorMenu : 'white'}
        className={'noprint'}
        fontWeight={render && selected ? '700' : '200'}
        style={{ fontSize: fSize, cursor: render ? 'pointer' : 'not-allowed' }}
      >
        {item}
      </Div>
    )
  }

  const BuildHeaderXL = () => {
    const t = useTranslation()

    return (
      <Flex
        justify={'space-between'}
        align={'center'}
        direction={'row'}
        width={'100vw'}
        style={{ paddingLeft: '20px', paddingRight: '20px' }}
      >
        <Flex direction={'row'} justify={'flex-start'}>
          <Div
            style={{ cursor: 'pointer' }}
            marginRight={'10px'}
            onClick={() => {
              history.push('/')
            }}
          >
            {title}
          </Div>
          <Div>
            <img src={'/images/logoRegistroWhite.svg'} alt="logo" height="30px" />
          </Div>
          <Div marginLeft={'20px'}>
            {renderMenuItem(
              `/${realm}/requests?status=pending&view=expirationDays,referenceContact&${
                isRegistrar ? 'registrarAssignation' : 'registryAssignation'
              }=assignedToMe`,
              t('Requests'),
              `/${realm}/requests`,
              'white',
              '1.2rem'
            )}
          </Div>
          <Div marginLeft={'20px'}>
            {renderMenuItem(
              `/${realm}/requests_expiring?registryAssignation=all`,
              t('RequestsExpiring'),
              `/${realm}/requests_expiring`,
              'white',
              '1.2rem'
            )}
          </Div>
          {/*<Can I={'Search'} a={'Request'}>*/}
          {/*  <Div marginLeft={'20px'}>*/}
          {/*    {renderMenuItem(*/}
          {/*      `/${realm}/search`,*/}
          {/*      t('Search'),*/}
          {/*      `/${realm}/search`,*/}
          {/*      'white',*/}
          {/*      '1.2rem'*/}
          {/*    )}*/}
          {/*  </Div>*/}
          {/*</Can>*/}
        </Flex>
        <Flex direction={'row'} style={{ gap: 20, fontSize: fontSize }}>
          <UserInfo />
          <LanguageSwitcher />
        </Flex>
      </Flex>
    )
  }

  const BuildHeaderS = () => {
    const t = useTranslation()
    const [visible, setVisible] = React.useState(false)
    const color = 'rgb(0, 91, 188)'
    const onClose = () => {
      setVisible(false)
    }
    return (
      <>
        <Drawer
          visible={visible}
          onClose={onClose}
          closeIcon={<CloseCircleOutlined style={{ fontSize: '1.5rem', color: color }} />}
          title={
            <Flex direction={'row'} justify={'flex-start'} style={{ gap: '5px' }}>
              <Div fontSize={'2.5rem'}>darwin</Div>
              <img src={'/images/logoRegistroNew.svg'} alt="logo" height="30px" />
            </Flex>
          }
        >
          <VerticalLayout style={{ gap: '20px', alignItems: 'center' }}>
            <Div>
              {renderMenuItem(
                `/${realm}/requests`,
                t('Requests'),
                `/${realm}/requests`,
                color,
                '1.2rem'
              )}
            </Div>

            <Can I={'Search'} a={'Request'}>
              <Div>
                {renderMenuItem(
                  `/${realm}/search`,
                  t('Search'),
                  `/${realm}/search`,
                  color,
                  '1.2rem'
                )}
              </Div>
            </Can>

            <UserInfo color={color} />

            <LanguageSwitcher color={color} />
          </VerticalLayout>
        </Drawer>
        <Flex
          justify={'space-between'}
          align={'center'}
          direction={'row'}
          width={'100vw'}
          style={{ paddingLeft: '20px', paddingRight: '20px' }}
        >
          <Flex direction={'row'} justify={'flex-start'}>
            <Div
              style={{ cursor: 'pointer' }}
              marginRight={'10px'}
              onClick={() => {
                history.push('/')
              }}
            >
              {title}
            </Div>
            <Div>
              <img src={'/images/logoRegistroWhite.svg'} alt="logo" height="30px" />
            </Div>
          </Flex>
          <Div
            onClick={() => {
              setVisible(!visible)
            }}
          >
            <MenuOutlined />
          </Div>
        </Flex>
      </>
    )
  }

  return (
    <>
      <MediaQuery break={'xxl'}>{BuildHeaderXL()}</MediaQuery>
      <MediaQuery break={'xl'}>{BuildHeaderXL()}</MediaQuery>
      <MediaQuery break={'l'}>{BuildHeaderXL()}</MediaQuery>
      <MediaQuery break={'m'}>{BuildHeaderXL()}</MediaQuery>
      <MediaQuery break={'s'}>{BuildHeaderS()}</MediaQuery>
      <MediaQuery break={'xs'}>{BuildHeaderS()}</MediaQuery>
    </>
  )
}
