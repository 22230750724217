import React, { FC } from 'react'
import { Divider, Space } from 'antd'
import { ButtonAssignToMe } from './Actions/ButtonAssignToMe'
import { ButtonReleaseAssignedRequest } from './Actions/ButtonReleaseAssignedRequest'
import { ButtonAssignToUser } from './Actions/ButtonAssignToUser'
import { AbilityContext } from '@nic/commons'

export interface AssignmentRequestActionsProps {
  requestId: number
  onSuccess?: () => void
  isAssignedUser?: boolean
}

/**
 * Pulsanti con le azioni che gli utenti possono eseguire con gli
 * assegnamenti delle richieste
 * @param props
 * @constructor
 */
export const AssignmentRequestActions: FC<AssignmentRequestActionsProps> = (props) => {
  const ability: any = React.useContext(AbilityContext)
  const actions = []

  if (ability.can('AssignToMe', 'Request')) {
    actions.push(<ButtonAssignToMe {...props} />)
  }
  if (ability.can('AssignToUser', 'Request')) {
    actions.push(<ButtonAssignToUser {...props} />)
  }
  if (ability.can('Release', 'Request')) {
    actions.push(<ButtonReleaseAssignedRequest {...props} />)
  }

  // console.log('AssignmentRequestActions', ability, actions)

  return <Space split={<Divider type={'vertical'} />}>{actions}</Space>
}
