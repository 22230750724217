import {
  apiGatewayAxiosInstance,
  apiGatewayDarwinAxiosInstance,
  customersAxiosInstance,
  darwinAxiosInstance,
  domainsAxiosInstance,
  domainsReservedAxiosInstance,
  proxiedCustomersAxiosInstance,
  proxiedDomainsAxiosInstance,
  securityAxiosInstance,
} from '@nic/nic-api'

export type DarwinConfig = {
  MIDA_HOST: string
  DARWIN_REQUEST_DURATION: number
  DARWIN_REQUEST_DURATION_CANCELLATION: number
  DARWIN_REQUEST_DURATION_DEACTIVATION: number
  JBILLING_HOST: string
  DARWIN_SERVICE_DEFAULT_ASSIGNATION_REGISTRAR_ADMIN: string //To remove soon
  DARWIN_SERVICE_DEFAULT_ASSIGNATION_REGISTRY_ADMIN: string //To remove soon
  DARWIN_SERVICE: string
  PROXIED_DARWIN_SERVICE: string
  CUSTOMERS_SERVICE: string
  SECURITY_SERVICE: string
  DOMAINS_SERVICE: string
  PROXIED_DOMAINS_SERVICE: string
  PROXIED_CUSTOMERS_SERVICE: string
  DOMAINS_RESERVED_SERVICE: string
  PROXIED_API_SERVICE: string
}

export function getWindowRunConfig(): DarwinConfig {
  // @ts-ignore
  return { ...window['runConfig'] }
}

/**
 * Sfruttando le istanze axios recuperate dalle api si definisce
 * per ogni servizio la baseUrl per evitare di utilizzare il ReverseProxy
 *
 * Per lo sviluppo basta riferisi al darwin-config.js presente in public,
 * negli altri casi basterà configurare per ogni ambiente (devel,prod,pubtest)
 * i vari configuration-*.js che si trovano nella cartella resources
 */
// console.log('DARWIN-CONFIG:', getWindowRunConfig())
darwinAxiosInstance.defaults.baseURL = getWindowRunConfig().DARWIN_SERVICE
apiGatewayDarwinAxiosInstance.defaults.baseURL = getWindowRunConfig().PROXIED_DARWIN_SERVICE
customersAxiosInstance.defaults.baseURL = getWindowRunConfig().CUSTOMERS_SERVICE
securityAxiosInstance.defaults.baseURL = getWindowRunConfig().SECURITY_SERVICE
domainsAxiosInstance.defaults.baseURL = getWindowRunConfig().DOMAINS_SERVICE
domainsReservedAxiosInstance.defaults.baseURL = getWindowRunConfig().DOMAINS_RESERVED_SERVICE
proxiedDomainsAxiosInstance.defaults.baseURL = getWindowRunConfig().PROXIED_DOMAINS_SERVICE
proxiedCustomersAxiosInstance.defaults.baseURL = getWindowRunConfig().PROXIED_CUSTOMERS_SERVICE
apiGatewayAxiosInstance.defaults.baseURL = getWindowRunConfig().PROXIED_API_SERVICE
