import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBan,
  faHourglass,
  faHourglassEnd,
  faLinkSlash,
  faPaperclip,
  faPaperPlane,
  faPen,
  faThumbsDown,
  faThumbsUp,
} from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Darwin } from '@nic/nic-api/types'
import { EventTypeColor } from '../Status/const'

export const EventTypeIcon: Record<Darwin.EventType, React.ReactNode> = {
  updatedRequiredDocuments: (
    <FontAwesomeIcon
      icon={faPaperclip as IconProp}
      color={EventTypeColor.updatedRequiredDocuments}
    />
  ), // registro
  cancelledCompleted: (
    <FontAwesomeIcon
      icon={faBan as IconProp}
      color={EventTypeColor.cancelled}
    />
  ), // registro
  cancelled: (
    <FontAwesomeIcon
      icon={faBan as IconProp}
      color={EventTypeColor.cancelledCompleted}
    />
  ), // registro
  submitted: (
    <FontAwesomeIcon
      icon={faPaperPlane as IconProp}
      color={EventTypeColor.submitted}
    />
  ), // registro
  toPending: (
    <FontAwesomeIcon
      icon={faHourglass as IconProp}
      color={EventTypeColor.toPending}
    />
  ), // registro
  rejected: (
    <FontAwesomeIcon
      icon={faThumbsDown as IconProp}
      color={EventTypeColor.rejected}
    />
  ), // registro
  registrarUpdated: <FontAwesomeIcon icon={faPen as IconProp} />, // registrar
  registryUpdated: (
    <FontAwesomeIcon
      icon={faPen as IconProp} //color={Type2Color.info}
    />
  ), // registrar
  registrarUpdateRejected: (
    <FontAwesomeIcon
      icon={faThumbsDown as IconProp} //color={Type2Color.error}
    />
  ), // registro
  registrarUpdateApproved: (
    <FontAwesomeIcon
      icon={faThumbsUp as IconProp} //color={Type2Color.success}
    />
  ), // registro
  toProcessing: (
    <FontAwesomeIcon
      icon={faHourglass as IconProp}
      //color={Type2Color.warning}
    />
  ), // registrar
  expired: (
    <FontAwesomeIcon
      icon={faHourglassEnd as IconProp}
      // color={Type2Color.error}
    />
  ), // registro
  completed: (
    <FontAwesomeIcon
      icon={faThumbsUp as IconProp} //color={Type2Color.success}
    />
  ),
  expiredCompleted: (
    <FontAwesomeIcon
      icon={faHourglassEnd as IconProp} //color={Type2Color.success}
    />
  ), // registro
  domainsDeactivated: (
    <FontAwesomeIcon
      icon={faLinkSlash as IconProp} //color={Type2Color.success}
    />
  ), // registro
};
