import { Darwin, NicScope } from '@nic/nic-api/types'
import React, { useContext } from 'react'
import { Breadcrumb } from '../Breadcrumb/Breadcrumb'
import { useScopeInfo } from './useScopeInfo'
import { LayoutContext } from '../../Context/LayoutContext'
import { useAuth } from '@nic/kc-auth'
import { useRouter } from './useRouter'
import { useTranslation } from '@nic/commons'

export function useBreadcrumb() {
  const t = useTranslation()
  const { scope } = useScopeInfo()
  const router = useRouter()
  const { setLayoutElement } = useContext(LayoutContext)
  const anomalyQueryParams = router.params as Darwin.GetRequestAnomaliesParam
  const { keycloak } = useAuth()
  const realm = keycloak?.realm as NicScope
  const isRegistrar = realm === 'registrar'

  React.useEffect(() => {
    setLayoutElement(
      'BREADCRUMB',
      <Breadcrumb
        elements={[
          { label: t('breadcrumb.dashboard'), path: `/${scope}/dashboard` },
          {
            label: t('breadcrumb.requests'),
            path: `/${realm}/requests?status=pending&view=expirationDays,referenceContact&${
              isRegistrar ? 'registrarAssignation' : 'registryAssignation'
            }=assignedToMe`,
          },
          {
            label: `${t('breadcrumb.request')}: ${anomalyQueryParams.id}`,
            path: `/${scope}/requests/${anomalyQueryParams.id}`,
          },
        ]}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope, anomalyQueryParams.id, t])
}
