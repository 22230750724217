import { useTranslation } from '@nic/commons'
import { Tag } from 'antd'
import React from 'react'
import { EventTypeIcon } from './const'

export const EventType = (props: { type: string; color: string }) => {
  const t = useTranslation();
  return (
    <Tag
      color={props.color}
      icon={EventTypeIcon[props.type]}
      style={{ fontSize: "0.8rem" }}
    >
      <span style={{ marginLeft: "5px" }}>{t(`events.${props.type}`)}</span>
    </Tag>
  );
};
