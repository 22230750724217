import { parse, stringify } from 'qs'
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import React from 'react'

/**
 * L'hook raccoglie i vari hooks che `react-router` offre e permette di ottenere i seguenti parametri:
 * - `pathname`
 * - `history`
 * - `query` : oggetto che presenta il contenuto di  `location.search`
 * - `match`
 * - `location`
 * - `history`
 * - `push`: che corrisponde `history.push`
 * - `replace`: che corrisponde `history.replace`
 */
export function useRouter() {
  const params = useParams()
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return React.useMemo(() => {
    const query = {
      ...parse(location.search, { ignoreQueryPrefix: true, allowDots: true }), // Convert string to object
      // ...params,
    }

    function urlBuilder(queryParamsObj: object) {
      return (
        location.pathname +
        // stringify({ ...qp, page: page - 1, size: pageSize }, { addQueryPrefix: true })
        stringify({ ...query, ...queryParamsObj }, { addQueryPrefix: true })
      )
    }

    return {
      // For convenience add push(), replace(), pathname at top level
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      params,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query,
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      history,
      pushHelper: (queryParamsObj: object) => {
        history.push(urlBuilder(queryParamsObj))
      },
      replaceHelper: (queryParamsObj: object) => {
        history.replace(urlBuilder(queryParamsObj))
      },
    }
  }, [history, location, params, match])
}
