import { Route, RouteComponentProps, RouteProps, useLocation } from 'react-router'
import { useAuth } from '@nic/kc-auth'
import { Link } from 'react-router-dom'
import { Page403 } from '../../Pages/Page403'
import Keycloak from 'keycloak-js'

export interface IProtectedRouteProps extends RouteProps {
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> | undefined
  allowed: boolean
}

export const KeycloakProtectedRoute: React.FC<IProtectedRouteProps> = ({
  component: Component,
  allowed,
  location,
  ...rest
}) => {
  const { keycloak } = useAuth()
  const { pathname: location2 } = useLocation()
  console.log('loc:', location2)
  if (keycloak && keycloak?.authenticated) {
    return (
      <Route
        {...rest}
        render={(props: any) => PrivateRender(Component, props, allowed, keycloak)}
      />
    )
  } else {
    return <Page403 />
  }
}

type RenderComponentType = (
  Component: any,
  props: RouteComponentProps<any>,
  allowed: boolean,
  keycloak?: Keycloak
) => React.ReactNode

const PrivateRender: RenderComponentType = (Component, props, allowed, keycloak) => {
  if (keycloak && keycloak.authenticated) {
    // comment
    return allowed ? <Component keycloak={keycloak} {...props} /> : <Page403 />
  } else {
    return (
      <div>
        <p>
          <Link to={'/login'}>Login</Link>
        </p>
      </div>
    )
  }
}
