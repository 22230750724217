import React from 'react'
import { Button, Space } from 'antd'
import { Can, useTranslation } from '@nic/commons'
import { stringify } from 'qs'
import { Link } from 'react-router-dom'
import { assigmentViewParam } from './assignmentUtils'
import { useScopeInfo } from '../Hooks/useScopeInfo'
import { useRouter } from '../Hooks/useRouter'

export interface IButtonsRequestsViewProps {
  style?: React.CSSProperties
}

export const ButtonsAssignationRequestsView: React.FC<IButtonsRequestsViewProps> = (props) => {
  return (
    <Space.Compact style={props.style} className={'buttons-assignation-req-view'}>
      {['all', 'assignedToMe', 'assigned', 'unassigned'].map((assignationView, i) => (
        <ButtonAssignationRequestView assignationRequestView={assignationView} key={i} />
      ))}
    </Space.Compact>
  )
}

function ButtonAssignationRequestView(props: { assignationRequestView: string }) {
  const { query, pathname } = useRouter()
  const t = useTranslation()
  const { scope } = useScopeInfo()
  const linkQueryObj = { ...query }
  linkQueryObj[assigmentViewParam(scope)] = props.assignationRequestView
  const queryAssignmentViewParam = query[assigmentViewParam(scope)]
  return (
    <Can I={props.assignationRequestView} a={'ViewAssignationRequests'}>
      <Button disabled={queryAssignmentViewParam === props.assignationRequestView}>
        <Link to={pathname + '?' + stringify(linkQueryObj)}>
          {t('assignmentRequests.view.' + props.assignationRequestView)}
        </Link>
      </Button>
    </Can>
  )
}
