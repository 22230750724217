import { Statistic } from 'antd'
import { requestValidityStatusColor as progressColor } from '../index'
import React, { CSSProperties } from 'react'
import { valueType } from 'antd/lib/statistic/utils'
import { useTranslation } from '@nic/commons'

export interface ExpirationRequestCountdownProps {
  lastDay: boolean
  value: valueType | undefined
  pause: boolean
  remainingDays: number
  style: CSSProperties
}

export function ExpirationCountdown(
  {
    lastDay,
    remainingDays,
    pause,
    style,
    value,
  }: ExpirationRequestCountdownProps) {
  const { Countdown } = Statistic
  const _color = progressColor(pause, remainingDays)
  const t = useTranslation()



  console.log("ExpirationCountdown - ",value,lastDay,
    remainingDays,
    pause,
    style,
    value,)
  if (value === undefined) {
    return (
      <Statistic
        style={style}
        title={t('reqExpirationCountdown.daysLeft')}
        value={'-'}
        valueStyle={{
          color: '#c6c6c6',
        }}
      />
    )
  }

  return lastDay ? (
    // Conteggio in minuti/ore visto che siamo nell'ultimo giorno
    <Countdown
      style={style}
      title={t('reqExpirationCountdown.remainingTime')}
      value={value}
      format={'H:m:s'}
      valueStyle={{
        color: _color,
      }}
    />
  ) : (
    // Conteggio in giorni
    <Countdown
      style={style}
      title={t('reqExpirationCountdown.daysLeft')}
      value={value}
      format={'D'}
      valueStyle={{
        color: _color,
      }}
    />
  )
}