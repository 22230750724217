import { Progress, Tooltip } from 'antd'
import { Flex } from '@nic/ui-comps'
import TimeTag from '../../Various/TimeTag/TimeTag'
import React from 'react'
import { ProgressType } from 'antd/lib/progress/progress'
import { useTranslation } from '@nic/commons'
import { getWindowRunConfig } from '../../../darwinConfig'

export interface IRequestProgress {
  /* data di creazione della richiesta per capire da quando parte*/
  creationDate: string
  /* giorni che mancano alla scadenza della richiesta */
  expirationDays: number
  /* durata totale della richiesta in giorni */
  duration: number
  /* pausa nel conteggio della richiesta */
  paused: boolean
}

/***
 * Barra di progresso che indica lo stato di avenzamento verso la chiusra
 * @param p
 * @constructor
 */
export function RequestProgressView(p: {
  remainingDays: number
  isPaused: boolean
  percentage: number
  isOverLabel: string
  type?: ProgressType
}) {
  const t = useTranslation()

  const currentReqProgressStatus = p.percentage < 100 ? (p.isPaused ? 'pause' : 'active') : 'over'
  return (
    <Tooltip
      placement='top'
      title={t(`requestProgressView.${currentReqProgressStatus}`, {
        remainingDays: p.remainingDays <  getWindowRunConfig().DARWIN_REQUEST_DURATION_DEACTIVATION? p.remainingDays - 1 : p.remainingDays,
      })}
    >
      <Progress
        strokeColor={requestValidityStatusColor(p.isPaused, p.remainingDays)}
        style={{ marginBottom: 20 }}
        type={p.type}
        percent={Math.round(p.percentage)}
        // format={() =>
        //   p.percentage < 100 ? (
        //     <FontAwesomeIcon
        //       icon={(p.isPaused ? faPause : faPlay) as IconProp}
        //       style={{ color: requestValidityStatusColor(p.isPaused, p.remainingDays) }}
        //     />
        //   ) : (
        //     <span style={{ color: requestValidityStatusColor(p.isPaused, p.remainingDays) }}>
        //       {' '}
        //       {p.isOverLabel}
        //     </span>
        //   )
        // }
        format={() =>
          Math.round(p.percentage) === 100 ? (
            <span style={{ color: requestValidityStatusColor(p.isPaused, p.remainingDays) }}>
              {p.isOverLabel}
            </span>
          ) : (
            // <>{Math.round(p.percentage)}%</>
            <span>
              {p.remainingDays}
              <div style={{ fontSize: '12px' }}>Giorni rimasti</div>
            </span>
          )
        }
        showInfo={true}
      />{' '}
    </Tooltip>
  )
}

/***
 * Barra di progresso che indica lo stato di avenzamento verso la chiusra
 * @param p
 * @constructor
 */
export function MainRequestProgressView(p: {
  remainingDays: number
  isPaused: boolean
  percentage: number
  isOverLabel: string
  type?: ProgressType
}) {
  const t = useTranslation()

  const currentReqProgressStatus = p.percentage < 100 ? (p.isPaused ? 'pause' : 'active') : 'over'
  // const style = {
  //   // color: '#000000',
  //   ':before': {
  //     boxSizing: 'unset',
  //   },
  // }
  return (
    <Tooltip
      placement='top'
      title={t(`requestProgressView.${currentReqProgressStatus}`, {
        remainingDays: p.remainingDays <  getWindowRunConfig().DARWIN_REQUEST_DURATION_DEACTIVATION? p.remainingDays - 1 : p.remainingDays
      })}
    >
      <Progress
        strokeWidth={6}
        strokeColor={requestValidityStatusColor(p.isPaused, p.remainingDays)}
        type={p.type}
        percent={Math.round(p.percentage)}
        format={() => (
          // p.percentage < 100 ? (
          //   <FontAwesomeIcon
          //     icon={(p.isPaused ? faPause : faPlay) as IconProp}
          //     style={{ color: requestValidityStatusColor(p.isPaused, p.remainingDays) }}
          //   />
          // ) : (
          //   <span style={{ color: requestValidityStatusColor(p.isPaused, p.remainingDays) }}>
          //     {' '}
          //     {p.isOverLabel}
          //   </span>
          // )
          <></>
        )}
        showInfo={false}
      />{' '}
    </Tooltip>
  )
}

/***
 * Label + indicazione temporale
 * @param p
 * @constructor
 */
export function TimeLabel(p: {
  label: string
  date: string
  color: string
  isOver: boolean
  hideTime?: boolean
}) {
  return (
    <RequestProgressLabel
      label={p.label}
      isOver={p.isOver}
      childrenStyle={{ color: p.isOver ? 'red' : '' }}
      children={
        <TimeTag
          created={p.date}
          color={p.color}
          fontWeight={700}
          bordered={true}
          hideTime={p.hideTime}
        />
      }
    />
  )
}

export interface IRequestProgressLabel {
  label: string
  isOver?: boolean
  childrenStyle?: React.CSSProperties
}

export function RequestProgressLabel(props: React.PropsWithChildren<IRequestProgressLabel>) {
  return (
    <Flex direction={'column'} justify={'right'} align={'right'}>
      <div>{props.label}</div>
      <div style={{ margin: '0 auto', ...props.childrenStyle }}>{props.children}</div>
    </Flex>
  )
}

/***
 * Label + indicazione numerica
 * @param p
 * @constructor
 */
export function DaysLeft(p: { label: string; remainingDays: number; isPaused: boolean }) {
  return (
    <RequestProgressLabel
      label={p.label}
      childrenStyle={{
        color: requestValidityStatusColor(p.isPaused, p.remainingDays),
      }}
      children={<div className={'days-left'}>{p.remainingDays > 0 ? p.remainingDays : 0}</div>}
    />
  )
}

/***
 * Colori per gestire gli stati della richiesta {paused, active, warning}
 * @param isPaused
 * @param remainingDays
 */
export function requestValidityStatusColor(isPaused: boolean, remainingDays: number) {
  const pausedColor = '#c6c6c6'
  const activeColor = '#1890FF'
  const warnColor = '#FF4D4F'

  if (isPaused) {
    return pausedColor
  }

  const x = remainingDays
  switch (true) {
    case 60 > x && x >= 45:
      return activeColor
    case 15 > x && x <= 30:
      return warnColor
    default:
      return activeColor
  }
}

export function pippoColor(isPaused: boolean, remainingDays: number) {
  const pausedColor = '#c6c6c6'
  // const activeColor = '#1890FF'
  const warnColor = '#FF4D4E'


  if (isPaused) {
    return pausedColor
  }

  const x = remainingDays
  switch (true) {
    case x === 0:
      return warnColor
    default:
      return pausedColor
  }
}

