import { useAuth } from '@nic/kc-auth'

import React from 'react'
import { Search } from './Pages/Operator/Search'
import { RegistrarsAnomalies } from './Pages/Operator/RegistrarsAnomalies'
import { Registrars } from './Pages/Operator/Registrars'
import { RealmSwitcher } from './Components/RealmSwitcher/RealmSwitcher'
import { RequestPage } from './Pages/RequestPage'
import { DashboardPage } from './Pages/DashboardPage'
import { NoMatchPage } from './Pages/NoMatchPage'
import { Page404 } from './Pages/Page404'
import { Page403 } from './Pages/Page403'
import { AbilityContext } from '@nic/commons'
import { KeycloakProtectedRoute } from './Components/KeycloakProtectedRoute/KeycloakProtectedRoute'

export function Routes() {
  const { keycloak } = useAuth()
  const ability: any = React.useContext(AbilityContext)

  const realm = keycloak?.realm

  //TODO inserire la condizione per definire l'utente unauthorized
  function isUnauthorized() {
    return ability.cannot('Permit', 'AccessDarwin')
    // return realm === 'registrar'
    //   ? !(
    //       keycloak?.realmAccess?.roles.includes('admin') ||
    //       keycloak?.realmAccess?.roles.includes('darwin') ||
    //       keycloak?.realmAccess?.roles.includes('domain')
    //     )
    //   : !(
    //       keycloak?.realmAccess?.roles.includes('operations') ||
    //       keycloak?.realmAccess?.roles.includes('operation-admins') ||
    //       keycloak?.realmAccess?.roles.includes('techs') ||
    //       keycloak?.realmAccess?.roles.includes('tech-admins')
    //     )
  }

  //console.log('**Token abilities ability.can("BeAdmin","Read")', ability.can('Admin', 'Admin'))

  if (isUnauthorized()) {
    return <Page403 />
  }

  return (
    <React.Fragment>
      <KeycloakProtectedRoute
        allowed
        path={`/${realm}/requests_expiring`}
        exact
        component={Search}
      />
      <KeycloakProtectedRoute
        // allowed={ability.can('Search', 'Request')}
        allowed
        path={`/${realm}/requests`}
        exact
        // component={realm === 'registrar' ? Requests : Search}
        component={Search}
      />
      {/*<KeycloakProtectedRoute allowed path={`/${realm}/requests`} exact component={Requests} />*/}

      <KeycloakProtectedRoute allowed path={'/:realm/requests/:id'} exact component={RequestPage} />

      <KeycloakProtectedRoute
        allowed
        path={`/registry/registrars`}
        exact
        component={RegistrarsAnomalies}
      />
      <KeycloakProtectedRoute
        allowed
        path={`/:realm/registrars/:registrarId/requests`}
        exact
        component={Registrars}
      />

      <KeycloakProtectedRoute
        allowed
        path={`/${realm}/registrars/:registrarId`}
        exact
        component={Registrars}
      />
      <KeycloakProtectedRoute
        allowed
        path={`/:realm/registrars/:registrarId/requests/:id`}
        exact
        component={RequestPage}
      />

      <KeycloakProtectedRoute
        allowed
        path={'/:realm/dashboard'}
        exact
        component={(data: any) => DashboardController(data, keycloak)}
      />

      <KeycloakProtectedRoute path={`/${realm}/403`} allowed component={Page403} />
      <KeycloakProtectedRoute path={'/404'} allowed component={Page404} />
      <KeycloakProtectedRoute allowed path={'/'} exact component={RealmSwitcher} />
    </React.Fragment>
  )
}

/***
 * Controllore che decide nel caso di DashboardPage
 * @param data
 * @param keycloak
 * @constructor
 */
const DashboardController = (data: any, keycloak: any) => {
  const { match } = data
  const { params } = match
  const realm = params.realm
  let component = <></>

  if (keycloak !== undefined && keycloak.realm !== realm) {
    return <NoMatchPage />
  }

  if (realm !== undefined) {
    switch (realm) {
      case 'registrar':
        component = <DashboardPage realm={'registrar'} />
        break
      case 'registry':
        component = <DashboardPage realm={'registry'} />
        break
      default:
        component = <NoMatchPage />
        break
    }
  }
  return <>{component}</>
}
