import React, { useContext } from 'react'
import { RequestContext } from '../Contexts/RequestContext'
import { Button } from 'antd'
import { VariationContext } from '../Providers/VariationProvider'
import { EditOutlined } from '@ant-design/icons'
import { useAssignmentRequest } from '../AssignmentRequests/useAssignmentRequest'
import { camelToDash, useTranslation } from '@nic/commons'
import { useRouter } from '../Hooks/useRouter'

export const EditRequestButton: React.FC = () => {
  const t = useTranslation()
  const { stateReqById } = useContext(RequestContext)
  const { dispatch: dispatchVariation } = useContext(VariationContext)
  const { isRegistryAssignedUser } = useAssignmentRequest()
  const canBeEdited =
    (stateReqById.data?.status === 'registrarUpdated' || stateReqById.data?.status === 'pending') &&
    isRegistryAssignedUser

  console.log(
    'canBeEdited',
    canBeEdited,
    isRegistryAssignedUser,
    stateReqById.data?.status === 'registrarUpdated' || stateReqById.data?.status === 'pending'
  )
  const { history } = useRouter()

  const confirm = () => {
    handleEditOn()
  }

  const handleEditOn = () => {
    dispatchVariation({ type: 'EDIT_ON' })
    history.push('?tab=edit')
    // message.success('Proposta di modifica processabile')
    // aaa();
  }

  const {
    state: { edit },
  } = useContext(VariationContext)
  return (
    <span className={camelToDash(EditRequestButton.name)}>
      <Button
        style={{ backgroundColor: canBeEdited ? 'orange' : '', border: 0 }}
        type="primary"
        danger
        shape="round"
        size={'middle'}
        icon={<EditOutlined />}
        disabled={!canBeEdited || edit}
        onClick={confirm}
      >
        {t('editRequest.button')}
      </Button>
    </span>
  )
}
